import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const menu = () => {
  return (
    <Layout>
      <Seo
        title="Menu"
        description="Servimos Pratos de Carne, tais como Medalão de novilho com crosta alheira e broa , Picanha com feijão preto, pratos de Peixe e Sobremesas variadas."
        defer={false}
      />
      <div className="w-full mx-auto lg:mt-10">
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md lg:h-32 lg:text-xl lg:font-medium">
          <h1>Ementa</h1>
          <span className="text-xs font-extralight lg:text-base">
            Coma bem, viva melhor!
          </span>
        </div>
        <nav className="flex justify-center items-center w-full bg-gray-800 h-8 text-white text-xs font-normal gap-3 lg:text-lg lg:gap-10 lg:font-medium">
          <Link to="#carne" activeClassName="active">
            Carne
          </Link>
          <Link to="#peixe" activeClassName="active">
            Peixe
          </Link>
          <Link to="#sobremesas" activeClassName="active">
            Sobremesas
          </Link>
        </nav>
        <main>
          <div
            className="w-full h-20 bg-carne-image bg-no-repeat bg-center bg-cover lg:h-48"
            id="carne"
          ></div>
          <div className=" w-4/5 mx-auto my-9 lg:my-20">
            <section className="grid grid-cols-1 gap-12 text-center lg:gap-14 lg:grid-cols-4 mb-10 ">
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/posta-monte-1_bx6qzn.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Posta à Monte Alegre
                </span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/picanha-1_ge32xh.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Picanha à Monte Alegre
                </span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639668244/novas%20fotos/1bcgqSJcZkStFRZ0ekw2gRkGxm8BuiNUW_svew6y.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Arroz de Pato
                </span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/medalhoes-novilho-bacon-2_lrf72t.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Medalhões de Novilho com Bacon
                </span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/bifinhos-bacon-2_w7mncm.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Bifinhos com Bacon
                </span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/bife-monte-1_piwfci.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Bife à Monte Alegre
                </span>
              </div>
              <div>
                <div className="w-full h-full">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/medalhoes-novilho-2_tmh3x1.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Medalhoes de Novilho com Alheira e Broa
                </span>
              </div>
              <div>
                <div className="w-full h-full">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/churrasquinh-vitela-1_qmu5po.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Churrasquinho de Vitela
                </span>
              </div>
            </section>
          </div>
          <div
            className="w-full h-20 bg-peixe-image bg-no-repeat bg-center bg-cover lg:h-48"
            id="peixe"
          ></div>
          <div className=" w-4/5 mx-auto my-9 lg:my-20">
            <section className="grid grid-cols-1 gap-12 text-center lg:gap-14 lg:grid-cols-4 mb-10  ">
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/terra-mar-2_wf2snh.jpg"
                    alt=""
                  />
                </div>
                <span>Terra e Mar</span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/filetes-4_oxf9ot.jpg"
                    alt=""
                  />
                </div>
                <span>Filetes à Marinheiro</span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/bacalhau-com-broa-1_f2nu6k.jpg"
                    alt=""
                  />
                </div>
                <span>Bacalhau com Broa</span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/baclhau-monte-1_kert8d.jpg"
                    alt=""
                  />
                </div>
                <span>Bacalhau à Monte Alegre</span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-90 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639668244/novas%20fotos/1bMPcTP-4CN5GvCpLKEoWskufDwUuTM8e_wsse2r.jpg"
                    alt=""
                  />
                </div>
                <span>Polvo à Lagareiro</span>
              </div>
              <div>
                <div className="w-full h-full ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-90 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639668244/novas%20fotos/1b1h6WxXJBmoc13ynEAKLsOerKH47dEhu_qpiwha.jpg"
                    alt=""
                  />
                </div>
                <span>Bacalhau à Lagareiro</span>
              </div>
            </section>
          </div>
          <div
            className="w-full h-20 bg-sobremesas-image bg-no-repeat bg-center bg-cover lg:h-48"
            id="sobremesas"
          ></div>
          <div className=" w-4/5 mx-auto my-9 lg:my-20">
            <section className="grid grid-cols-1 gap-12 text-center lg:gap-14 lg:grid-cols-4 mb-10  ">
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1_rk6Ev2tvfELcZ-eNYiYb-P37y-CUn8a_t1xwic.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Pudim Molotof
                </span>
              </div>
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664741/sobremesas/1aIaC-gH9-5sOk26Aeb4xuolk2Oh54b68_b3ilwr.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Bolo de Chocolate Ferrero
                </span>
              </div>
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1_wtA1KQvC_mksyQhI_qHS3iMNyL7xqRS_vh7yq8.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Pudim Tradicional
                </span>
              </div>
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1a2FgufqmjDwc05wnez5kiDu5L8_GtfdH_qcyzqd.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Bolo de Noz
                </span>
              </div>
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1arhB8rPtF8XFdpIPO_gZJ_Qq370sK99M_i81r2l.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Mousse de Oreo
                </span>
              </div>
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1ahd309ZSMcJ6lW700mDErtlU6IkMoPOW_sh7c8h.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Mousse de Chocolate
                </span>
              </div>
              <div>
                <div className="w-full h-full  ">
                  <img
                    className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                    src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664742/sobremesas/1_stB3yH4MHQ-XQpLPMi_jZgfgBxJoMdd_a8dlhs.jpg"
                    alt=""
                  />
                </div>
                <span className=" text-sm md:text-base lg:text-lg">
                  Leite Creme à Monte Alegre
                </span>
              </div>
            </section>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default menu
